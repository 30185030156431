import React from "react";

class FAQ extends React.Component {
  render() {
    return (
      <div id="FAQ">
        <div>
          <span id="title">Pay</span> <br />
          <span className="heading">Gghkdgfjdfhl hbga jgdfhg?</span>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit quia
            nulla eligendi incidunt impedit. Unde id eos officia, velit
            voluptate ducimus praesentium tenetur sunt, suscipit, officiis quia
            harum deleniti placeat repudiandae laborum doloribus magnam at cum!
            Facere, praesentium distinctio? Unde eos doloremque et tempora
            harum, deserunt earum vero id nobis, perferendis sit ex consequuntu
            r quam vel, ut sequi nulla nostrum amet? Rerum et deleniti
            asperiores sapiente voluptatem sint cupiditate nihil non, deserunt
            ducimus iusto illum fugiat explicabo, perspiciatis qui tempore
            suscipit nostrum nam officia? Temporibus incidunt provident odio
            fugiat aliquam porro soluta ad similique voluptatum neque recusandae
            unde doloremque reiciendis quis, dolorem nobis, asperiores,
            quibusdam praesentium debitis sit? In, fugiat tenetur magni totam
            non deleniti neque animi qui illo. Fugit, libero. Temporibus eveniet
            est alias in voluptatibus saepe assumenda, sit laboriosam veniam
            sapiente delectus voluptatum quas at cum provident aliquid ipsum,
            totam incidunt, blanditiis animi molestias laborum neque earum!
            Veritatis?{" "}
          </p>
        </div>
        <div>
          <span id="title">Information</span> <br />
          <span className="heading">Gghkdgfjdfhl hbga jgdfhg?</span>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur
            dolore quaerat, explicabo quidem natus vero, impedit commodi
            eligendi recusandae repellendus odit voluptas possimus d olorem
            dicta neque accusantium ratione. Quaerat, nostrum? Lorem ipsum dolor
            sit amet consectetur adipisicing elit. Totam mollitia a impedit!
            Iusto modi quos molestiae nobis pariatur dicta corrupti perferendis
            iste molestias aliquam praesentium, veritatis ut laudantium, aliquid
            neque doloribus nostrum tempora sunt totam, minus consequatur
            facilis ducimus? Dolorem blanditiis vel quis accusantium perferendis
            consequatur nemo reiciendis, sint quaerat rem molestias, quae
            placeat v oluptates, quos laboriosam molestiae! Suscipit,
            architecto. Quas, sed sapiente sunt voluptatibus esse quod
            consequatur deserunt maiores!
          </p>
        </div>
        <div>
          <span id="title">Guarantee</span> <br />
          <span className="heading">Gghkdgfjdfhl hbga jgdfhg?</span>
          <p>
            {" "}
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Perspiciatis laudantium odio saepe, libero quibusdam sit minima
            laboriosam magnam cum, repellat est aut nisi explicabo harum
            cupiditate! Fugiat facere, perspiciatis id officiis adipisci
            consectetur ullam nam repudiandae expedita exercitationem assumenda
            repellendus voluptates hic, ex saepe dicta? Dolor numquam aliquam
            alias dolore?
          </p>
        </div>
      </div>
    );
  }
}

export default FAQ;
